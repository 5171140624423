import * as Sentry from '@sentry/react'

import { MODE } from 'share/constants.ts'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { useEffect } from 'react'

const initializeSentry = () => {
  if (MODE !== 'production') return
  const dsn = import.meta.env.VITE_SENTRY_DSN
  if (!dsn) return
  Sentry.init({
    dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.extraErrorDataIntegration({
        depth: 100
      })
    ],
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
    environment: 'production'
  })
}

const addInitialBreadcrumbToSentry = () => {
  Sentry.addBreadcrumb({
    category: 'app',
    message: 'App initialized',
    level: 'info',
    data: {
      initial_url: window.location.href,
      query_params: window.location.search
    }
  })
  // const span = Sentry.startInactiveSpan({ op: 'initialize', name: 'app-init-transaction', forceTransaction: true })
  // span.setAttribute('initial_url', window.location.href) // Capture the starting URL
  // span.setAttribute('query_params', window.location.search) // Capture the query params
  // span.end()
}

export { initializeSentry, addInitialBreadcrumbToSentry }
